/* Make Links use the same color as the rest of the text. */
a {
  color: inherit;
}

/* Keep paragraphs readable, even on extra-wide screens. Center paragraphs if the max width is reached. */
p {
  max-width: 50em;
  margin:auto;
}

ion-card {
  max-width: 50em;
  margin: auto;
}